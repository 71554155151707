<template>
	<div class="rentQi">
		<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 11 }">
			<a-form-model-item label="优惠名称" prop="Name">
				<a-input v-model="form.Name" allowClear />
			</a-form-model-item>
			<a-form-model-item label="类型" prop="ConcessionType">
				<a-select show-search allowClear placeholder="请选择" v-model="form.ConcessionType" disabled>
					<a-select-option v-for="item in ConcessionTypeList" :key="item.value" :value="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<!--      <a-form-model-item label="课包简介">
        <a-tree-select v-model="form.ClassFeeCategory" :tree-data="treeData2" :replaceFields="{ key: 'Id', value: 'title' }" placeholder="请选择" allowClear> </a-tree-select>
      </a-form-model-item> -->
			<a-form-model-item label="永不过期">
				<a-select allowClear placeholder="请选择" v-model="form.NeverExpire" @change="NeverExpireChange">
					<a-select-option value="0">否</a-select-option>
					<a-select-option value="1">是</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="有效期" v-show="isShowEffect" prop="Effect">
				<a-range-picker v-model="form.Effect" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
					:placeholder="['开始时间', '结束时间']" allowClear @change="dateChange" />
			</a-form-model-item>
			<!-- <a-form-model-item label="优惠方案类型" prop="ConcessionIsMain">
        <a-select show-search allowClear placeholder="请选择" v-model="form.ConcessionIsMain">
          <a-select-option v-for="item in ConcessionIsMainList" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item> -->
			<a-form-model-item label="折扣率(%)">
				<a-input-number v-model="form.AmountDiscount" allowClear  style="width:390px;"  />&nbsp;&nbsp;%
			</a-form-model-item>
			<a-form-model-item label="是否为满减优惠">
				<a-select allowClear placeholder="请选择" v-model="form.IsReduce">
					<a-select-option :value="0">否</a-select-option>
					<a-select-option :value="1">是</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="满足金额">
				<a-input-number v-model="form.SatisfyMoney" allowClear style="width:390px;"  />&nbsp;&nbsp;元
			</a-form-model-item>
			<a-form-model-item label="抵减现金">
				<a-input v-model="form.AmountCash" allowClear style="width:390px;"  />&nbsp;&nbsp;元
			</a-form-model-item>
			<a-form-model-item label="抵减现金范围">
				<a-select allowClear placeholder="请选择" v-model="form.IsAll">
					<a-select-option :value="0">每件产品</a-select-option>
					<a-select-option :value="1">整单</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="是否在线配送">
				<a-select allowClear placeholder="请选择" v-model="form.Distribution">
					<a-select-option :value="0">否</a-select-option>
					<a-select-option :value="1">是</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="适用范围">
				<a-select allowClear placeholder="请选择" v-model="form.EffectArea" @change="EfectAreaChange">
					<a-select-option :value="1">全体</a-select-option>
					<a-select-option :value="2">指定部门</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label=" " :colon="false" prop="EffectSchoolId" v-show="isShowDepartment">
				<a-tree-select v-model="form.EffectSchoolId" :tree-data="treeData" placeholder="请选择" allowClear
					tree-checkable> </a-tree-select>
			</a-form-model-item>
			<a-form-model-item label="是否为员工优惠">
				<a-select allowClear placeholder="请选择" v-model="form.IsInner">
					<a-select-option :value="0">否</a-select-option>
					<a-select-option :value="1">是</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="N件产品以上">
				<a-input-number v-model="form.MinCount" allowClear style="width:390px;"  />&nbsp;&nbsp;件
			</a-form-model-item>
			<a-form-model-item label="是否可以与其他重叠">
				<a-select allowClear placeholder="请选择" v-model="form.IsCover">
					<a-select-option :value="0">不可重叠</a-select-option>
					<a-select-option :value="1">可以重叠</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="指定产品">

				<a-input v-model="form.ProductName" allowClear @click="_showAlert" />
			</a-form-model-item>
			<a-form-model-item label="描述">
				<a-textarea v-model="form.Note" :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="描述" />
			</a-form-model-item>

		</a-form-model>

		<a-drawer title="选择指定产品" placement="right" width="700" :closable="false" @close="_closeAlert"
			:visible="selectCourse">
			<div class="page-main">
				<div class="screen-list">
					<a-form-model layout="inline" :model="souForm">
						<a-form-model-item label="名称">
							<a-input placeholder="请输入关键字" v-model="souForm.name" allowClear />
						</a-form-model-item>
						<a-form-model-item label="所属大类">
							<a-select show-search allowClear placeholder="请选择" v-model="souForm.ParentCategoryId"
								@change="ParentCategoryChange">
								<a-select-option v-for="item in GoodCategoryList" :key="item.Id" :value="item.Id">
									{{ item.Name }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
						<a-form-model-item label="所属小类">
							<a-select show-search allowClear placeholder="请选择" v-model="souForm.CategoryId">
								<a-select-option v-for="item in GoodCategorySubList" :key="item.Id" :value="item.Id">
									{{ item.Name }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-form-model>
					<div class="form-btn">
						<a-button type="primary" @click="_searchBtn">开始查询</a-button>
					</div>
				</div>
				<a-table bordered :columns="alertColumns" :dataSource="alertData" :showAlertInfo="true"
					:pagination="false" :rowSelection="{selectedRowKeys: bookSelectedRowKeys, onChange: bookOnChange}">
					<!-- <div slot="ThumbUrl" slot-scope="text, record, index">
                    <img :src="text+'?imageView2/1/w/60/h/60'" style="width: 30px;height: 30px;" alt="">
                </div>
                <div slot="status" slot-scope="text, record, index">
                    <span class="status-icon" :style="text=='禁用'?'background: #E81B1C':'background: #4199F3'"></span> {{text}}
                </div> -->
				</a-table>
				<div style="margin: 20px 0; text-align: right;">
					<a-pagination showQuickJumper :defaultCurrent="1" :total="pageTotal" @change="_PageChange" />
				</div>
			</div>
			<div class="alert-footer">
				<a-button style="margin-right:25px" @click="_closeAlert">取消</a-button>
				<a-button type="primary" @click="_saveSelect">确定</a-button>
			</div>
		</a-drawer>

	</div>
</template>

<script>
	export default {
		name: 'rentQi',

		data() {
			return {
				form: {
					Name: '',
					ConcessionType: 2,
					ConcessionIsMain: 1,
					NeverExpire: "0",
					Effect: [],
					AmountDiscount: 0,
					IsRepeat: 0,
					SatisfyMoney: 0,
					AmountCash: '',
					IsAll: 1,
					Distribution: 0,
					EffectArea: 1,
					IsInner: 0,
					MinCount: '',
					IsCover: 0,
					ProductList: '',
					ProductName: '',
					EffectSchoolId: undefined,
					Note: '',
					NeedReview: 0,
					IsReduce: 0,

				},
				souForm: {
					name: undefined, //关键字
					ParentCategoryId: undefined, // 状态
					CategoryId: undefined //部门
				},
				pageTotal: 0,
				page: 1,
				isShowDepartment: false,
				rules: {
					Name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
					ConcessionType: [{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}],
					CourseVoucherId: [{
						required: true,
						message: '请输入赠送券的类别',
						trigger: 'blur'
					}],
					ConcessionIsMain: [{
						required: true,
						message: '请选择优惠方案类型',
						trigger: 'blur'
					}],
					AmountDiscount: [{
						required: true,
						message: '请输入折扣率',
						trigger: 'blur'
					}],
					AmountCash: [{
						required: true,
						message: '请输入抵减现金',
						trigger: 'blur'
					}],
					Effect :[{
						required: true,
						message: '请选择有效时间',
						trigger: 'change'
					}]
				},
				userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
				treeData: [],
				CourseVoucherList: [],
				ConcessionTypeList: [],
				ApplyToObjectsList: [],
				ClassType: [],
				ConcessionIsMainList: [],
				selectCourse: false,
				GoodCategoryList: [],
				GoodCategorySubList: [],
				Id: '',
				alertColumns: [{
						title: '编号',
						dataIndex: 'Number',
						width: 150,
						align: 'center',
					},
					{
						title: '名称',
						dataIndex: 'Name',
						align: 'center',
					},
					{
						title: '零售价',
						dataIndex: 'RetailPrice',
						align: 'center'
					}
				],
				alertData: [],
				bookSelectedRowKeys: [],
				bookSelectedRow: [],
				Products: [],
				isShowEffect:true,
			}
		},
		props: {
			formData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		created() {

			// 如果是编辑数据，修正表单数据类型
			if (this.formData.Id) {
				this.Id = this.formData.Id
				for (const key in this.form) {
					if (typeof this.form[key] === 'boolean') {
						this.form[key] = !!this.formData[key]
					} else if (key === 'EffectSchoolId') {
						this.form[key] = this.formData[key] ? this.formData[key].split(',') : []
						if (this.formData[key] != "") {
							this.isShowDepartment = true;
						}
					} else if (key === 'Effect') {
						this.form.Effect = [this.formData.EffectFrom, this.formData.EffectTo]
					} else {
						this.form[key] = this.formData[key]
					}
				}
			}
			console.log(this.form)
			//课程优惠方案赠送券的类别
			this.$axios.get(80811003, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.CourseVoucherList = res.data.data

				}
			})
			//获取课程类别
			this.$axios.get(80811008, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.ConcessionTypeList = res.data.data

				}
			})
			this.$axios.get(80811009, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.ConcessionIsMainList = res.data.data
				}
			})

			// 获取部门
			this.$axios.get(
				5836120, {
					userId: this.userinfo.uid
				},
				res => {
					if (res.data.code == 1) {
						this.treeData = res.data.data.schoollist
						// this.StatusList = res.data.data.statuslist
					}
				}
			)

			this.ClassTypeList()
		},
		methods: {
				NeverExpireChange(val) {
				let rules = {
					...this.rules
				}
				if (val === "1") {
					this.form.Effect = []
					if (rules.Effect) {
						this.$refs.ruleForm.clearValidate('Effect')
					}
					this.isShowEffect=false
					delete rules.NeverExpire
				} else {
					rules.Effect = [{
						required: true,
						message: '请选择有效时间',
						trigger: 'change'
					}]
					this.isShowEffect=true
				}
				this.rules = {
					...rules
				}
			},
			_PageChange(page) {
				this.page = page
				this._showAlert()
			},
			_searchBtn() {
				this.page = 1
				this._showAlert()
			},
			_saveSelect() {
				let str = [];
				let strall = [];
				this.Products = this.bookSelectedRow;
				this.Products.map(res => {

					str.push(res.Name)
					strall.push(res.Id + "|" + res.Name + "|" + res.Number)
				});
				this.form.ProductName = str.join(',');
				this.form.ProductList = strall.join(',');
				this.selectCourse = false;
			},
			bookOnChange(selectedRowKeys, selectedRows) {
				//选中的数据的key
				this.bookSelectedRowKeys = selectedRowKeys;
				this.bookSelectedRow = selectedRows;
			},
			_showAlert() {
				console.log(this.formData.Id);
				this.selectCourse = true;
				this._GoodCategoryList()
				let data = {
					ProName: this.souForm.name,
					ParentCategoryId: this.souForm.ParentCategoryId,
					CategoryId: this.souForm.CategoryId,
					Page: this.page,
					// Id:this.Id,
					PageSize: 10
				}
				this.$axios.get(80812003, data, res => {
					if (res.data.code == 1) {
						this.alertData = res.data.data;
						this.pageTotal = res.data.count
						this.page = this.page


						// this.alertData.map((res2,index)=>{
						//     if(res1.Id == res2.Id){
						//         this.bookSelectedRowKeys.push(index)
						//     }
						// })

					} else {
						this.$message.success(res.data.msg);
					}
				});
			},
			_closeAlert() {
				this.selectCourse = false;
			},
			ParentCategoryChange(val) {
				this.souForm.CategoryId = undefined
				let data = {
					Pid: val,
				}
				this.$axios.get(80812005, data, res => {
					if (res.data.code == 1) {
						this.GoodCategorySubList = res.data.data
					}
				})
			},
			// 适用范围选择事件
			EfectAreaChange(val) {
				let rules = {
					...this.rules
				}
				this.isShowDepartment = val === 2
				if (val !== 2) {
					this.form.EffectSchoolId = ''
					if (rules.EffectSchoolId) {
						this.$refs.ruleForm.clearValidate('EffectSchoolId')
					}

					delete rules.EffectSchoolId
				} else {
					rules.EffectSchoolId = [{
						required: true,
						message: '请选择部门',
						trigger: 'change'
					}]
				}
				this.rules = {
					...rules
				}
			},
			ClassTypeList() {
				//获取课程类别
				this.$axios.get(80811004, {
					label: 'FeeClass'
				}, res => {
					if (res.data.code == 1) {
						this.ClassType = [...res.data.data]
					}
				})

			},
			_GoodCategoryList() {
				//获取课程类别
				this.$axios.get(80812004, {

				}, res => {
					if (res.data.code == 1) {
						this.GoodCategoryList = res.data.data
					}
				})

			},
			// 日期控件值变化
			dateChange(val) {
				if (val[0]) {
					this.form.EffectFrom = val[0]
					this.form.EffectTo = val[1]
				} else {
					this.form.EffectFrom = ''
					this.form.EffectTo = ''
				}
			},
			handleChange(key, val) {
				if (this.form[key].some(item => item === 'all')) {
					this.form[key] = ['all']
					this.$refs[key].blur()
				}
			},
			onSubmit() {
				return new Promise((resolve, reject) => {
					this.$refs.ruleForm.validate(valid => {
						if (valid) {
							resolve(this.form)
						} else {
							reject(false)
						}
					})
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.rentQi {
		background: #ffffff;
		margin-top: 15px;
		min-height: 800px;
		padding: 15px;
	}

	.page-main {
		padding: 20px;

		.screen-list {
			margin: 0 0 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			:deep(.ant-select-selection--single),
      :deep(.ant-form-item-control) {
        min-width: 150px;
      }

			.form-btn {
				button+button {
					margin-left: 10px;
				}
			}
		}
	}
</style>
